import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Evento } from "../../components/evento";
import { EventoDatos } from "../../components/eventoDatos";
import { apiUrlGestor, apiUrlAced, apiUrlSaveSpot, urlRoot, SaveSpotGuarda } from "../../global";
import { Form } from "../../components/forms";
import { FormSend } from "../../components/formSend";
import { FormTutor } from "../../components/formsTutor";
import Footer from "../../components/Footer";
import { Navbar } from "../../components/NavbarSimple";
import Grid from "@mui/material/Grid";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import sleep from "sleep-promise";

function Eventos() {
  const { eventId } = useParams();
  const [evento, setEvento] = useState([]);
  const [claveCampus, setClaveCampus] = useState(null);
  const [nivelEvent, setNivelEve] = useState(null);
  const [areaEvent, setAreaEve] = useState(null);
  const [registrado, setRegistrado] = useState(false);
  const [campusData, setCampusData] = useState([]);
  const [areaData, setAreaData] = useState([]);
  const [BtnShow, setBtnShow] = useState(true);
  const [__tituloEvento, settituloEvento] = useState("");
  const [aviso, setAviso] = useState(0);
  const [campusSoad, setCampusSoad] = useState([]);
  //ESTADOS PARA FORMULARIO
  const [correo, setCorreo] = useState("");
  const [mostrarFormulario, setMostrarFormulario] = useState(null);
  const [eventoNormal, seteventoNormal] = useState(true);
  const [isBotonBloqueado, setIsBotonBloqueado] = useState(false);
  const [isEnviando, setIsEnviando] = useState(false);
  const [imageError, setImageError] = useState(true);
  const [muestraCapt, setMuestraCapt] = useState(true);
  const [isLoading, setIsLoading] = useState(false); //Variable para el loading
  const [botonOculto, setBotonOculto] = useState(false);
  let navigate = useNavigate();

  //const [fetchDone, setFetchDone] = useState(false);

  const handleForm = () => setMostrarFormulario(null);

  //Buscamos si la URL tiene un gracias
  const currentUrl = window.location.href;
  const isThankYouPage = currentUrl.includes("/gracias");
  //Mensaje gracias
  const urlSearchParams = new URLSearchParams(currentUrl.split("?")[1]);
  const mensajePersonalizado = urlSearchParams.get("g");

  const [captchaValue, setCaptchaValue] = useState(null);
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    document.getElementById("captchaP").textContent = "";
  };
  const recaptchaRef = useRef();
  //console.log(isThankYouPage);

  //ANCLA
  if (window.location.hash === "#confirmacion") {
    // Encontrar el elemento al que deseas desplazarte
    const confirmationElement = document.getElementById("confirmacion");

    // Realizar el desplazamiento suave hasta el elemento
    if (confirmationElement) {
      confirmationElement.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    setBotonOculto(false);
  }, []); // El segundo argumento, un array vacío, asegura que se ejecute solo una vez

  useEffect(() => {
    // Verificar si ya se ha realizado la consulta previamente
    //setClaveCampus("test");
    if (evento.length === 0) {
      fetch(apiUrlGestor + "obtenerEventos.php?match=All&url=" + eventId)
        .then((response) => response.json())
        .then((data) => {
          console.log("aqui");
          console.log(data.events);
          if (data.events && data.events.length > 1) {
            const eventAtIndex1 = data.events[1];
            if (eventAtIndex1 && eventAtIndex1.type) {
              navigate("/" + eventId);
              return null;
            }
          }
          setEvento(data.events);
          //ENVIAMOS OTRO DATO POR SEPARADO
          settituloEvento(data.events[0].event.title);
          //const claveCampus = data.events[0].event.keywords[0];
          const claveCampus = data.events[0].event.fcoe;
          const tipoEvento = data.events[0].event.tipo_evento;

          if (
            tipoEvento === "902101" ||
            tipoEvento === "902102" ||
            tipoEvento === "35839594891577"
          )
            seteventoNormal(false);

          if (claveCampus === "FCOE_VC") {
            if (localStorage.getItem("campusStorage")) {
              //let arryCampusKey = ["FCOE_" + localStorage.getItem("campusStorage"),];
              let arryCampusKey =
                "FCOE_" + localStorage.getItem("campusStorage");
              setClaveCampus(arryCampusKey);
            } else setClaveCampus("FCOE_A");
          } else setClaveCampus(claveCampus);

          const nivelEvent = data.events[0].event.nivel;
          setNivelEve(nivelEvent);

          const area = data.events[0].event.area;
          setAreaEve(area);
        })
        .catch((error) => {
          console.log("entro al error");
          console.log(error);
        });
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  }, [evento, eventId, navigate]);

  //OBTENER RESPUESTA DE LA API DE HUBSPOT DE CORREO
  //Se llama a la función al dar e clic en enviar
  const validarCorreoFnc = () => {
    setIsBotonBloqueado(true);
    var buscaCorreo = true;
    var correoValido = true;
    const serv = window.location.hostname; //localhost

    if (!captchaValue) {
      document.getElementById("captchaP").textContent = "El campo es requerido";
    }
    if (correo === "") {
      document.getElementById("correoVal").textContent =
        "Ingresa un correo electrónico";
    }
    else{
      if (!esCorreoElectronico(correo)) {
        document.getElementById("correoVal").textContent =
        "Ingresa un correo electrónico valido";
        correoValido= false;
      }
    }

    if (aviso === 0) {
      document.getElementById("avisosP").textContent = "El campo es requerido";
    }
    
    if (serv === "localhost") setCaptchaValue("true");

    if (!captchaValue || correo === "" || aviso === 0 || correoValido === false) {
      buscaCorreo = false;
      setIsBotonBloqueado(false);
    }

    if (buscaCorreo) {
      // document.getElementById("correoMsg").textContent = "Gracias";
      document.getElementById("correoVal").textContent = "";
      document.getElementById("correoValidate").disabled = true;
      document.getElementById("avisos").disabled = true;

      fetch(apiUrlSaveSpot + "hubspot/contacts/findByEmail/" + correo, {
        method: "GET",
        headers: {
          "Content-Type": "",
        },
      })
        .then((resp) => {
          return resp.json();
        })
        .then((json) => {
          const arrayResp = JSON.parse(JSON.stringify(json));
          document.getElementById("btnValida").style.display = "none";
          if (arrayResp["status"] === 200) {
            var propiedad = "cual_perfil_te_describe_mejor_";
            var valorBuscado = "Soy padre, madre o tutor(a)";
            if (
              arrayResp["data"]["properties"][
                "cual_perfil_te_describe_mejor_"
              ] === null
            ) {
              propiedad = "padre_o_tutor";
              valorBuscado = "true";
            }
            if (arrayResp["data"]["properties"][propiedad] === valorBuscado) {
              setMostrarFormulario("formPapa");
              recaptchaRef.current.reset();
              setMuestraCapt(false);
              // document.getElementById("reform").style.display = "block";
              setBotonOculto(true);
            } else {
              //SE ENVIA DIRECTO
              //setMostrarFormulario("prospecto");
              document.getElementById("btnValida").style.display = "block";

              const utm_term_input = localStorage.getItem("utm_term_Local");
              const utm_medium_input = localStorage.getItem("utm_medium_Local");
              const utm_source_input = localStorage.getItem("utm_source_Local");
              const utm_content_input =
                localStorage.getItem("utm_content_Local");
              const utm_campaign_input =
                localStorage.getItem("utm_campaign_Local");
              const utm_crm_campaign_input = localStorage.getItem(
                "utm_crm_campaign_Local"
              );
              const utm_cmpa_input = localStorage.getItem("utm_cmpa_Local");

              if (evento[0].event.fonteva)
                var eventoValue = evento[0].event.fonteva.replace(/EVE-/g, "");

              let grandoSend="profesional"
              if(evento[0].event.nivel==="03")
              grandoSend="prepatec"

              var cmpaSend = evento[0].event.cmpa;
              if (cmpaSend === "" || cmpaSend === null)
                cmpaSend = campusData[0].campana;
              //LABEL:
              const credentials = {
                email: correo,
                cmpa: cmpaSend,
                event: eventoValue,
                formName:
                  evento[0].event.id_evento + evento[0].event.urlname,
                grade: grandoSend,
                landingUrl:
                  "https://calendario.tec.mx/evento/" + evento[0].event.urlname,
                utmTerm: utm_term_input,
                utmCmpa: utm_cmpa_input,
                utmMedium: utm_medium_input,
                utmSource: utm_source_input,
                utmContent: utm_content_input,
                utm_campaign: utm_campaign_input,
                utmCampaign: utm_crm_campaign_input,
                sendhubspot: "true",
                sendsalesforce: "true",
                SaveSpotServer: SaveSpotGuarda
              };
              const options = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(credentials),
              };
              fetch(apiUrlGestor + "enviaRegistro.php", options)
                .then((response) => response.json())
                .then(sleep(2000))
                .then((data) => {

                  console.log(data);

                  if(data.data[0].status!==200){
                    //SaveSpor mandó error, así que mostramos el formulario
                    recaptchaRef.current.reset();
                    setMostrarFormulario("form");
                    setMuestraCapt(false);
                    setBotonOculto(true);

                  }
                  else{

                  document.getElementById("btnValida").style.display = "none";
                  localStorage.removeItem("utm_term_Local");
                  localStorage.removeItem("utm_medium_Local");
                  localStorage.removeItem("utm_source_Local");
                  localStorage.removeItem("utm_content_Local");
                  localStorage.removeItem("utm_campaign_Local");
                  localStorage.removeItem("utm_crm_campaign_Local");
                  localStorage.removeItem("expiracion");
                  setIsEnviando(true);
                  navigate(
                    "../evento/" +
                      evento[0].event.urlname +
                      "/gracias?g=2#confirmacion"
                  );
                }
                })
                .catch((error) => {
                  console.log(error);
                });

              //console.table(credentials);
            }
          } else {
            recaptchaRef.current.reset();
            setMostrarFormulario("form");
            setMuestraCapt(false);
            // document.getElementById("reform").style.display = "block";
            setBotonOculto(true);
          }
        });
    }
  };

  const reiniciarForm = () => {
    setMuestraCapt(true);
    var input = document.getElementById("correoValidate");
    input.value = "";
    document.getElementById("correoValidate").disabled = false;
    document.getElementById("avisos").checked = false;
    setAviso(0);
    setCorreo("");
    setMostrarFormulario("null");
    setCaptchaValue(null);
    recaptchaRef.current.reset();
    setIsBotonBloqueado(false);
    document.getElementById("btnValida").style.display = "block";
    // document.getElementById("reform").style.display = "none";
    setBotonOculto(false);
    document.getElementById("avisos").disabled = false;
    document.getElementById("correoMsg").textContent =
      "Si ya te has registrado previamente, por favor usa el mismo correo.\n Si eres padre, madre o tutor(a), ingresa el correo del estudiante interesado.";
  };

  function esCorreoElectronico(texto) {
    const expresionRegular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    //const expresionRegular = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return expresionRegular.test(texto);
  }

  //Captura el valor del input de correo
  const handleChange = (e) => {
    setCorreo(e.target.value);
    document.getElementById("correoVal").textContent = "";
    //document.getElementById("reform").style.display = "block";
    localStorage.setItem("emailSendInfo", e.target.value);

    if (esCorreoElectronico(e.target.value)) {
      document.getElementById("btnValida").style.display = "block";
    } else {
      // document.getElementById("btnValida").style.display = "none";
      document.getElementById("correoVal").textContent =
        "Ingresa una dirección de correo electrónico valida";
    }
  };

  const handleChangeAviso = (e) => {
    e.target.checked ? setAviso(1) : setAviso(0);
    document.getElementById("avisosP").textContent = "";
  };
  //DATOS DE CAMPUS ACED
  useEffect(() => {
    setCampusSoad("");
    if (claveCampus) {
      console.log(nivelEvent);
      var gradeCampus = "profesional";
      if (nivelEvent === "03") gradeCampus = "prepatec";

      const claveCampusString = String(claveCampus);
      const keyCamp = claveCampusString.replace("FCOE_", "");
      if (keyCamp === "VC") gradeCampus = "otro";

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ grade: gradeCampus, sf_key: keyCamp }),
      };
      // Realizar la solicitud Fetch aquí
      fetch(apiUrlAced + "campuses/search", options)
        .then((response) => response.json())
        .then((datos) => {
          setCampusData(datos.data);

          if (!datos.data[0].sf_key) {
            // El valor de datos.data[0].sf_key no existe o es falsy
            // Puedes tomar alguna acción aquí, si es necesario
            setCampusSoad(keyCamp);
          } else {
            // El valor de datos.data[0].sf_key existe y no es falsy
            setCampusSoad(datos.data[0].sf_key);
          }
        });
    }
  }, [claveCampus, nivelEvent]);
  //END DATOS CAMPUS ACED
  const img = new Image();
  img.src = `${process.env.PUBLIC_URL}/img/banners/banner-profesional-${campusSoad}.webp`;

  img.onerror = () => {
    setImageError(true);
  };

  img.onload = () => {
    setImageError(false);
  };
  //DATOS DE AREA ACED
  useEffect(() => {
    if (areaEvent) {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };
      // Realizar la solicitud Fetch aquí
      fetch(apiUrlAced + "areas/" + areaEvent, options)
        .then((response) => response.json())
        .then((datos) => {
          setAreaData(datos.data);
          // setFetchDone(true); // Marcar la solicitud como completada
        });
    }
  }, [areaEvent]);
  //DATOS DE AREA ACED

  const eventoRegistrado = () => {
    setRegistrado(true);
  };

  const sendConfirmEmail = (event) => {
    console.log("send email");
    const msjSend = document.getElementById("msjSend");
    msjSend.innerText = "Enviando...";

    const tipoEnvio = event.target.id;
    console.log(tipoEnvio);

    var emailSendValidate = localStorage.getItem("emailSendInfo");
    var utm_correo_padre = localStorage.getItem("utm_correo_padre");
    var credentialsS;

    //const referSend=window.location.href
    credentialsS = {
      utm_correo_padre: utm_correo_padre,
      utm_nombre_padre: "SaveSpot",
    };
    const credentials = credentialsS;
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentials),
    };

    fetch(
      apiUrlSaveSpot +
        "core/email-services/validate-data-email/" +
        emailSendValidate,
      options
    )
      .then((resp) => {
        localStorage.removeItem("emailSendInfo");
        // console.log(resp)
        //return resp.json()
        msjSend.innerText = "";
        document.getElementById("msjSendRequest").style.display = "block";
        localStorage.removeItem("emailSendInfo");
        localStorage.removeItem("utm_correo_padre");
        document.getElementById("smjThank").style.display = "none";
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("emailSendInfo");
      });

    /*
    if(tipoEnvio==="enviarCorreoFRP"){
     credentialsS = {
      utm_correo_padre : utm_correo_padre,
      utm_nombre_padre: "SaveSpot",
      email: emailSendValidate,
      extraProps: { atr_prospecto_validado: "false" },
      sendsalesforce: false,
      refer : referSend,
      sendhubspot: true,
    }
  }
    else{
       credentialsS = {
        email: emailSendValidate,
        utm_nombre_padre: "SaveSpot",
        extraProps: { atr_prospecto_validado: "false" },
        sendsalesforce: false,
        refer : referSend,
        sendhubspot: true,
      }
    }
    const credentials = credentialsS
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentials),
    };

    fetch("https://admision.tec.mx/savespot/api/core/email-services/validate-data-email/", options)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        msjSend.innerText = "";
        document.getElementById("msjSendRequest").style.display = "block";
        localStorage.removeItem("emailSendInfo");
        localStorage.removeItem("utm_correo_padre");
        document.getElementById("smjThank").style.display = "none";
      })
      .catch((error) => {
        console.log(error);
        localStorage.removeItem("emailSendInfo");
        localStorage.removeItem("utm_correo_padre");
      });*/
  };

  var ruta = window.location.origin;
  var palabra = "localhost";

  var index = ruta.indexOf(palabra);
  if (index < 0) ruta = ruta + "/" + urlRoot;

  useEffect(() => {
  setIsEnviando(false);
  setCaptchaValue(null);
  setBotonOculto(false);
  setMuestraCapt(true);
    setIsBotonBloqueado(false)

    if (isThankYouPage) {
      setBtnShow(false); // Actualiza el estado de btnShow a false

    }
  }, [isThankYouPage]);
  return (
    <div>
      {isLoading ? (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      ) : (
        <div>
          <Navbar tituloEvento={__tituloEvento} 
          nivel={nivelEvent === "03" ? 'prepatec' : 'profesional'} />
          <Grid
            item
            xs={12}
            sx={{
              mb: 6,
              backgroundImage: `url(${
                imageError
                  ? `${process.env.PUBLIC_URL}/img/banners/banner-default.webp`
                  : `${process.env.PUBLIC_URL}/img/banners/banner-profesional-${campusSoad}.webp`
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              boxShadow: `1px 30px 30px 10px #bfbfbf`,
              height: 140,
              "@media (max-width: 900px)": {
                height: 96,
              },
            }}
          >
            {" "}
          </Grid>

          <Evento
            EventoNormal={eventoNormal}
            Evento={evento}
            Registrado={registrado}
            InfoCampus={campusData}
            InfoArea={areaData}
            btnshow={BtnShow}
          />
          <br></br>
          <br></br>
          {eventoNormal && (
            <>
              <Grid container spacing={4}>
                <Grid item md={1} xs={1}></Grid>
                <Grid
                  className="borderForm"
                  item
                  md={10}
                  xs={11}
                  sx={{
                    display: { xs: "flex", md: "block" },
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid item md={12} xs={11}>
                    <Grid
                      item
                      xs={12}
                      sx={{ pb: 2, display: isThankYouPage ? "none" : "block" }}
                    >
                      <h2 id="btnValidaR" style={{ color: "#109AFF" }}>
                        ¡Regístrate aquí!
                      </h2>
                    </Grid>
                    {isThankYouPage ? (
                      <div id="confirmacion">
                        <Grid container spacing={12}>
                          <Grid item xs={12}>
                            <h2 id="smjThank">
                              {mensajePersonalizado === "1#confirmacion"
                                ? "¡Gracias por tu registro!"
                                : mensajePersonalizado === "2#confirmacion"
                                ? "¡Hola de nuevo!"
                                : mensajePersonalizado === "3#confirmacion"
                                ? "¡Gracias por tu interés!"
                                : "¡Gracias por tu interés!"}
                            </h2>
                            {mensajePersonalizado && (
                              <div>
                                {mensajePersonalizado === "1#confirmacion" && (
                                  <p>
                                    Te sugerimos seguir explorando{" "}
                                    <a href={"/"+urlRoot}>
                                      nuestros otros eventos disponibles
                                    </a>
                                    . ¡Esperamos verte pronto!
                                  </p>
                                )}
                                {mensajePersonalizado === "2#confirmacion" && (
                                  <>
                                    <p id="msjSend">
                                      Hemos realizado tu registro con la
                                      información que tenemos asociada a tu
                                      correo, ¡gracias por tu interés! Si deseas
                                      recibir aparte el detalle de tus datos
                                      relacionados
                                      <strong
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        onClick={sendConfirmEmail}
                                        id="enviarCorreoFR"
                                      >
                                        {" "}
                                        da clic aquí
                                      </strong>
                                      , o también puedes encontrarnos en nuestro{" "}
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                          nivelEvent === "03"
                                            ? "https://admision.tec.mx/contact-hub-prepatec/home"
                                            : "https://admision.tec.mx/contact-hub/home"
                                        }
                                      >
                                        <b>Contact Hub</b>
                                      </a>
                                      . <br></br>
                                      <br></br>
                                      Te sugerimos seguir explorando{" "}
                                      <a href={"/"+urlRoot}>
                                        nuestros otros eventos disponibles
                                      </a>
                                      . ¡Esperamos verte pronto!
                                    </p>

                                    <p
                                      id="msjSendRequest"
                                      style={{ display: "none" }}
                                    >
                                      <h3>¡Listo!</h3>Hemos enviado los datos
                                      relacionados a tu correo electrónico.
                                      Recuerda que puedes encontrarnos en
                                      nuestro{" "}
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                          nivelEvent === "03"
                                            ? "https://admision.tec.mx/contact-hub-prepatec/home"
                                            : "https://admision.tec.mx/contact-hub/home"
                                        }
                                      >
                                        <b>Contact Hub</b>
                                      </a>
                                      , o ver más información en{" "}
                                      <a href="https://tec.mx/es">
                                        <b>Tec.mx</b>
                                      </a>
                                      .
                                    </p>
                                  </>
                                )}
                                {mensajePersonalizado === "3#confirmacion" && (
                                  <>
                                    <p id="msjSend">
                                      Hemos registrado a tu hijo(a) como
                                      asistente, y enviamos una confirmación a
                                      su correo. Si deseas recibir aparte el
                                      detalle de los datos asociados a tu correo
                                      <strong
                                        style={{
                                          color: "blue",
                                          cursor: "pointer",
                                        }}
                                        onClick={sendConfirmEmail}
                                        id="enviarCorreoFRP"
                                      >
                                        {" "}
                                        da clic aquí
                                      </strong>
                                      , o también puedes encontrarnos en nuestro{" "}
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                          nivelEvent === "03"
                                            ? "https://admision.tec.mx/contact-hub-prepatec/home"
                                            : "https://admision.tec.mx/contact-hub/home"
                                        }
                                      >
                                        <b>Contact Hub</b>
                                      </a>
                                      . <br></br>
                                      <br></br>
                                      Te sugerimos seguir explorando{" "}
                                      <a href={"/"+urlRoot}>
                                        nuestros otros eventos disponibles
                                      </a>
                                      . ¡Esperamos verte pronto!
                                    </p>

                                    <p
                                      id="msjSendRequest"
                                      style={{ display: "none" }}
                                    >
                                      <h3>¡Listo!</h3>Hemos enviado los datos
                                      relacionados a tu correo electrónico.
                                      Recuerda que puedes encontrarnos en
                                      nuestro{" "}
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                          nivelEvent === "03"
                                            ? "https://admision.tec.mx/contact-hub-prepatec/home"
                                            : "https://admision.tec.mx/contact-hub/home"
                                        }
                                      >
                                        <b>Contact Hub</b>
                                      </a>
                                      , o ver más información en{" "}
                                      <a href="https://tec.mx/es">
                                        <b>Tec.mx</b>
                                      </a>
                                      .
                                    </p>
                                  </>
                                )}
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    ) : (
                      <form>
                        <Grid container spacing={1}>
                          <Grid item md={7} lg={7} xs={12}>
                            {/* */}
                            <input
                              type="text"
                              placeholder="Correo electrónico del estudiante"
                              className="form-control effect-10"
                              id="correoValidate"
                              onBlur={handleChange}
                            />
                            <p id="correoMsg">
                            Si ya te has registrado previamente, por favor usa el mismo correo.<br></br> Si eres padre, madre o tutor(a), ingresa el correo del estudiante interesado.
                            </p>
                            <p className="errorForm" id="correoVal"></p>
                          </Grid>
                          <Grid item md={5} lg={5} xs={12}>
                            {botonOculto ? (
                              <input
                                id="reform"
                                className="btnreg ValidateBottom"
                                onClick={reiniciarForm}
                                type="button"
                                value="REINICIAR FORMULARIO"
                              />
                            ) : null}
                            {/* Aquí puedes mostrar otros elementos o contenido según la condición */}
                          </Grid>

                          <Grid
                            style={{ display: muestraCapt ? "block" : "none" }}
                            item
                            xs={12}
                          >
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey="6Le_JVwUAAAAAHuFmFujxMmNUgOwrBpNa8I92Naw"
                              onChange={handleCaptchaChange}
                            />
                            <p className="errorForm" id="captchaP"></p>
                          </Grid>

                          <Grid item xs={12}>
                            <div className="form-group" id="select">
                              <label
                                className="checkbox-inline"
                                htmlFor="avisos"
                              >
                                <input
                                  type="checkbox"
                                  id="avisos"
                                  name="avisos"
                                  onChange={handleChangeAviso}
                                />{" "}
                                He leído y acepto los términos y condiciones del{" "}
                                <a
                                  href="https://tec.mx/es/aviso-de-privacidad-prospectos"
                                  target="blank_"
                                  rel="noopener"
                                >
                                  <b>AVISO DE PRIVACIDAD</b>
                                </a>{" "}
                                del Tecnológico de Monterrey.
                              </label>
                              <p className="errorForm" id="avisosP"></p>
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <div id="btnValida" className="formsReg">
                              <input
                                className={`btnreg ValidateBottom ${
                                  isBotonBloqueado ? "bloqueado" : ""
                                }`}
                                onClick={validarCorreoFnc}
                                type="button"
                                value={
                                  isBotonBloqueado
                                    ? "Procesando..."
                                    : isEnviando
                                    ? "Enviando..."
                                    : "Continuar"
                                }
                                disabled={isBotonBloqueado}
                              />
                            </div>
                            <br></br>
                          </Grid>
                        </Grid>
                      </form>
                    )}

                    {/* FORMS */}
                    {mostrarFormulario && (
                      <div>
                        {mostrarFormulario === "formPapa" && (
                          <>
                            <div style={{ padding: "1%" }}>
                              Ya tenemos datos asociados a tu correo, pero como
                              padre o madre de familia. Para poder concluir el
                              registro, es necesario completar la información
                              del estudiante.
                            </div>

                            <br></br>
                            <FormTutor
                              Evento={evento}
                              ClCampus={claveCampus}
                              NivelEvento={nivelEvent}
                              EventoRegistrado={eventoRegistrado}
                              emailInput={correo}
                              viweForm={handleForm}
                              AvisoInput={aviso}
                              InfoCampus={campusData}
                            />
                          </>
                        )}

                        {mostrarFormulario === "prospecto" && (
                          <>
                            <FormSend
                              Evento={evento}
                              ClCampus={claveCampus}
                              NivelEvento={nivelEvent}
                              EventoRegistrado={eventoRegistrado}
                              emailInput={correo}
                              AvisoInput={aviso}
                              viweForm={handleForm}
                            />
                          </>
                        )}

                        {mostrarFormulario === "form" && (
                          <>
                            <h4>
                              Para concluir tu registro, completa los siguientes
                              datos.
                            </h4>
                            <br></br>
                            <Form
                              Evento={evento}
                              ClCampus={claveCampus}
                              NivelEvento={nivelEvent}
                              EventoRegistrado={eventoRegistrado}
                              emailInput={correo}
                              AvisoInput={aviso}
                              viweForm={handleForm}
                              InfoCampus={campusData}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid item md={1} xs={1}></Grid>
              </Grid>
            </>
          )}
          <br></br>
          <br></br>
          <EventoDatos
            Evento={evento}
            Registrado={registrado}
            InfoCampus={campusData}
          />

          <br></br>
          <Footer></Footer>
        </div>
      )}
    </div>
  );
}

export default Eventos;
