import React, { useEffect, useState, useCallback } from "react";
import { apiCoreSave, apiUrlGestor, SaveSpotGuarda } from "../global";
import "react-datepicker/dist/react-datepicker.css";
import "./forms.css";
import "./General.css";
import * as moment from "moment";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import sleep from "sleep-promise";
//import IntlTelInput from 'react-intl-tel-input';
//import 'react-intl-tel-input/dist/main.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Form({
  Evento,
  ClCampus,
  NivelEvento,
  EventoRegistrado,
  emailInput,
  viweForm,
  AvisoInput,
  InfoCampus
}) {
  const [campus, setCampus] = useState(null);
  const [mustraSelects, setmustraSelects] = useState(false);
  const [mustraArea, setmustraArea] = useState(false);
  const [mustraPrograma, setmusPrograma] = useState(false);
  const [periodos, setPeriodos] = useState(null);
  const [areaInteres, setAreaInteres] = useState(null);
  const [programaInteres, setProgramasInteres] = useState(null);
  const [isEnglish, setisEnglish] = useState(0); // ESTE VARIABLE SE REFIERE AL IDIOMA
  const [nombre, setNombre] = useState("");
  const [apellidoPaterno, setApellidoPaterno] = useState("");
  const [apellidoMaterno, setApellidoMaterno] = useState("");
  const [telefono, setTelefono] = useState("");
  const [fecha, setFecha] = useState("");
  const [EscuelaProveedoraS, setEscuelaProveedoraS] = useState("");
  // valores seleccionados de cada campo
  const [campusS, setCampusS] = useState("");
  const [periodosS, setPeriodosS] = useState("");
  const [areaInteresS, setAreaInteresS] = useState("");
  const [programaInteresS, setProgramasInteresS] = useState("");
  const [aviso, setAviso] = useState(AvisoInput);
  const [showForm, setShowForm] = useState(true);
  const [isBotonBloqueado, setIsBotonBloqueado] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const handlePhoneChange = (value, country) => {
    setPhoneNumber(value);
    setTelefono(value);
    document.getElementById("telefonoP").textContent = "";
  };

  //console.log(areaInteresS);
  //console.log(programaInteresS);

  //console.log("phone="+phoneNumber);
  /*  const url = new URL(window.location.href);
  const cValue = url.searchParams.get("c");*/

  function restaFecha(anniosRest) {
    var today = new Date();

    let annios = 1000 * 60 * 60 * 24 * 365 * anniosRest;
    let resta = today.getTime() - annios;

    let fechaNueva = new Date(resta);
    var yearN = fechaNueva.getFullYear();

    return yearN;
  }

  const handleButtonClick = () => {
    setShowForm(true);
  };
  let navigate = useNavigate();

  //const claveCampusClear = ClCampus[0].replace(/FCOE_/g, '');
  const claveCampusClear =
    ClCampus && ClCampus.length > 0 ? ClCampus.replace(/FCOE_/g, "") : "";
  //console.log("claveCampusClear=" + claveCampusClear);
  // Imformación del evento
  //console.log(Evento);
  //console.log(Evento[0].event.urlname);

  const mandarTodo = (e) => {
    setIsBotonBloqueado(true);
    var fechacorregida = moment(fecha);
    if (
      nombre === "" ||
      apellidoPaterno === "" ||
      telefono === "" ||
      fecha === "" ||
      claveCampusClear === "" ||
      periodosS === "" ||
      areaInteresS === "" ||
      programaInteresS === "" ||
      aviso === 0
    ) {
     
      setIsBotonBloqueado(false);
      //mostramos mensaje de que se debe de subir en los campos que no se han llenado
      if (nombre === "" || nombre.trim() === "") {
        if (isEnglish) {
          document.getElementById("nombreP").textContent = "Ingresa el nombre.";
        } else {
          document.getElementById("nombreP").textContent = "Campo Obligatorio";
        }
      }
      if (apellidoPaterno === "" || apellidoPaterno.trim() === "") {
        if (isEnglish) {
          document.getElementById("apellidoPaternoP").textContent =
            "Ingresa tu primer apellido.";
        } else {
          document.getElementById("apellidoPaternoP").textContent =
            "Campo Obligatorio";
        }
      }
      if (telefono === "" || telefono.trim() === "") {
        if (isEnglish) {
          document.getElementById("telefonoP").textContent =
            "Ingresa tu número de teléfono.";
        } else {
          document.getElementById("telefonoP").textContent =
            "Campo Obligatorio";
        }
      }
      if (fecha === "") {
        if (isEnglish) {
          document.getElementById("fechaP").textContent =
            "Ingresa tu fecha de nacimiento";
        } else {
          document.getElementById("fechaP").textContent = "Campo Obligatorio";
        }
      }

      if (campusS === "") {
        if (isEnglish) {
          document.getElementById("campusP").textContent =
            "Selecciona un campus.";
        } else {
          document.getElementById("campusP").textContent = "Campo Obligatorio";
        }
      }
      if (periodosS === "") {
        if (isEnglish) {
          document.getElementById("periodoP").textContent =
            "Ingresa una fecha esperada de ingreso.";
        } else {
          document.getElementById("periodoP").textContent = "Campo Obligatorio";
        }
      }
      if(mustraSelects){
      if (areaInteresS === "") {
        if (isEnglish) {
          document.getElementById("areaP").textContent =
            "Ingresa un área de interés.";
        } else {
          document.getElementById("areaP").textContent = "Campo Obligatorio";
        }
      }
      if (programaInteresS === "") {
        if (isEnglish) {
          document.getElementById("programaP").textContent =
            "Ingresa una carrera de interés.";
        } else {
          document.getElementById("programaP").textContent =
            "Campo Obligatorio";
        }
      }
    }
      if (aviso === 0) {
        if (isEnglish) {
          document.getElementById("avisosP").textContent =
            "El campo es requerido";
        } else {
          document.getElementById("avisosP").textContent = "Campo Obligatorio";
        }
      }
    } else {
      //localStorage.setItem('paisID', JSON.parse(paisS).key)
      const cmpaInput = document.getElementById("cmpaForm");
      const cmpaValue = cmpaInput.value.replace(/CMPA-/g, "");

      const eventoInput = document.getElementById("eventoForm");
      const eventoValue = eventoInput.value.replace(/EVE-/g, "");

      const contextoForm = document.getElementById("contextoForm");
      const concetxtoValue = contextoForm.value;

      const nivelInput = document.getElementById("nivelForm");
      const NivelValue = nivelInput.value;

      const idLandingInput = document.getElementById("idLanding");
      const idLandingValue = idLandingInput.value;
      console.log(idLandingValue);

      const utm_sourceInput = document.getElementById("utm_source");
      const utm_sourceValue = utm_sourceInput.value;

      const utm_termInput = document.getElementById("utm_term");
      const utm_termValue = utm_termInput.value;
      console.log(utm_termValue);

      const utm_mediumInput = document.getElementById("utm_medium");
      const utm_mediumValue = utm_mediumInput.value;

      const referInput = document.getElementById("refer");
      const referValue = referInput.value;

      const landingFormInput = document.getElementById("landingForm");
      const landingFormValue = landingFormInput.value;

      const nombreRegistroVal = landingFormValue; //"Eventos " + concetxtoValue; //Eventos Guadalajara

      const utm_term_input = localStorage.getItem("utm_term_Local");
      const utm_content_input = localStorage.getItem("utm_content_Local");
      const utm_campaign_input = localStorage.getItem("utm_campaign_Local");
      const utm_crm_campaign_input = localStorage.getItem("utm_crm_campaign_Local");
      const utm_cmpa_input = localStorage.getItem("utm_cmpa_Local");
      const campusId=Evento[0].event.id_campus;
      
      let CampusKey='';
      if (campusId === "VC") {
        if (localStorage.getItem("campusStorage")) {
           CampusKey = localStorage.getItem("campusStorage");
        } else  CampusKey ="A";
      } else CampusKey=campusId;

      var cmpaSend= cmpaValue
      if(cmpaSend==="" || cmpaSend === null)
      cmpaSend=InfoCampus[0].campana

      let grandoSend="profesional"
      if(NivelValue==="03")
      grandoSend="prepatec"

      // console.error("test");
      //LABEL:
      const credentials = {
        name: nombre,
        lastName: apellidoPaterno,
        secondLastName: apellidoMaterno,
        celularPhone: telefono,
        email: emailInput,
        birthDate: fechacorregida.format("DD/MM/YYYY"),
        escuelasprov: EscuelaProveedoraS, //JSON.parse(paisS).key,  //JSON.pase(paisS).nombre   <- Para enviar el nombre y no el ID
        sede: CampusKey,
        period: JSON.parse(periodosS).key,
        area: JSON.parse(areaInteresS).key,
        program: JSON.parse(programaInteresS).key,
        avisos: aviso,
        cmpa: cmpaSend,
        event: eventoValue,
        contexto: concetxtoValue,
        grade: grandoSend,
        landingUrl: "https://calendario.tec.mx/evento/"+Evento[0].event.urlname,
        formName: Evento[0].event.id_evento+Evento[0].event.urlname,
        landing: nombreRegistroVal,
        utmTerm: utm_term_input,
        utmMedium: utm_mediumValue,
        utmSource: utm_sourceValue,
        utmContent: utm_content_input,
        utmCampaign: utm_campaign_input,
        utmCrmCampaign: utm_crm_campaign_input,
        utmCmpa: utm_cmpa_input,
        refer: referValue,
        //ENVIAR A hubspot
        sendhubspot: "true",
        sendsalesforce: "true",
        SaveSpotServer: SaveSpotGuarda
      };
      //console.log(credentials);
      //console.log(JSON.stringify(credentials))

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // mode: 'no-cors', // Establecer el modo no-cors
        body: JSON.stringify(credentials),
      };
      //console.log(options);

      fetch(apiUrlGestor + "enviaRegistro.php", options)
        .then((response) => response.json())
        .then(sleep(2000))
        .then((data) => {
          // Manipular los datos de la respuesta
          console.log(data);

          // alert("se envió con exito");
          localStorage.removeItem("utm_term_Local");
          localStorage.removeItem("utm_medium_Local");
          localStorage.removeItem("utm_source_Local");
          localStorage.removeItem("utm_content_Local");
          localStorage.removeItem("utm_campaign_Local");
          localStorage.removeItem("utm_crm_campaign_Local");
          localStorage.removeItem("expiracion");
          setIsBotonBloqueado(false);
          viweForm(null);
          navigate(
            "../evento/" + Evento[0].event.urlname + "/gracias?g=1#confirmacion"
          );
        })
        .catch((error) => {
          console.log(error);
          setIsBotonBloqueado(false);
        });
      //localStorage.setItem('correo', correo)
    }

    e.preventDefault();
    EventoRegistrado();
    //navigate("../evento/" + Evento[0].event.urlname + "/gracias#confirmacions");
  };

  // =================== CAMPUS ======================
  const consultaCampus = useCallback(() => {
    var detailsCampus = {
      valor: "05",
    };
    var formBody = [];
    for (var property in detailsCampus) {
      var encodeKey = encodeURIComponent(property);
      var encodeValue = encodeURIComponent(detailsCampus[property]);
      formBody.push(encodeKey + "=" + encodeValue);
    }
    formBody = formBody.join("&");
    fetch(apiCoreSave + "/campus_internacional.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        //console.log(json)
        setCampus(json);
      });
  }, []);

  // =================== PERIODOS ======================
  const consultaPeriodos = useCallback(() => {
    let campusx;
    try {
      campusx = claveCampusClear;
    } catch (error) {
      campusx = "";
    }

    var detailsPeriodo = {
      nivel: NivelEvento,
      campus: campusx,
      ingles: isEnglish,
    };
    var formBody = [];
    for (var property in detailsPeriodo) {
      var encodeKey = encodeURIComponent(property);
      var encodeValue = encodeURIComponent(detailsPeriodo[property]);
      formBody.push(encodeKey + "=" + encodeValue);
    }
    formBody = formBody.join("&");
    fetch(apiCoreSave + "/tecmx/jsons/periodos.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((respPeriodos) => {
        return respPeriodos.json();
      })
      .then((jsonPeriodos) => {
        //console.log(jsonPeriodos)
        setPeriodos(jsonPeriodos);
      });
  }, [isEnglish, claveCampusClear, NivelEvento]);

  // =================== AREA ======================
  const consultaAreaInteres = useCallback(
    (claveCampusClear, periodoS, NivelEvento) => {
      let campus, periodo;
      try {
        campus = claveCampusClear;
        periodo = JSON.parse(periodoS).key;
      } catch (error) {
        campus = "";
        periodo = "";
      }

      var detailsPeriodo = {
        campus: campus,
        periodo: periodo,
        nivel: NivelEvento,
        carrera: null,
        ingles: isEnglish,
      };
      var formBody = [];
      for (var property in detailsPeriodo) {
        var encodeKey = encodeURIComponent(property);
        var encodeValue = encodeURIComponent(detailsPeriodo[property]);
        formBody.push(encodeKey + "=" + encodeValue);
      }
      formBody = formBody.join("&");
      fetch(apiCoreSave + "/tecmx/jsons/areas.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody,
      })
        .then((resp) => {
          return resp.json();
        })
        .then((json) => {
          if (json === null) {
            // Realizar una nueva solicitud si el resultado es null
            return fetch(apiCoreSave + "/tecmx/jsons/areas.php", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
              },
              body: formBody,
            }).then((resp) => resp.json());
          } else {
            //console.log(json)
            setAreaInteres(json);
          }
        });
    },
    [isEnglish]
  );

  // =================== PROGRAMA ======================
  const consultaPrograma = useCallback(
    (claveCampusClear, periodoS, areaS, NivelEvento) => {
      let campus, periodo, area;
      try {
        campus = claveCampusClear;
        periodo = JSON.parse(periodoS).key;
        area = JSON.parse(areaS).key;
      } catch (error) {
        campus = "";
        periodo = "";
        area = "";
      }

      var detailsPeriodo = {
        campus: campus,
        periodo: periodo,
        area: area,
        nivel: NivelEvento,
        ingles: isEnglish,
      };
      var formBody = [];
      for (var property in detailsPeriodo) {
        var encodeKey = encodeURIComponent(property);
        var encodeValue = encodeURIComponent(detailsPeriodo[property]);
        formBody.push(encodeKey + "=" + encodeValue);
      }
      formBody = formBody.join("&");
      fetch(apiCoreSave + "/tecmx/jsons/carreras.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody,
      })
        .then((resp) => {
          return resp.json();
        })
        .then((json) => {
          if (json === null || json.length === 0) {
            // Realizar una nueva solicitud si el resultado es null
            return fetch(apiCoreSave + "/tecmx/jsons/carreras.php", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
              },
              body: formBody,
            }).then((resp) => resp.json());
          } else {
            //console.log(json)
            setProgramasInteres(json);
          }
        });
    },
    [isEnglish]
  );

  //============================ DATA ==================================================

  const handleChange = (e) => {
    if (e.target.id === "nombre") {
      setNombre(e.target.value);
    } else if (e.target.id === "apellidoPaterno") {
      setApellidoPaterno(e.target.value);
    } else if (e.target.id === "apellidoMaterno") {
      setApellidoMaterno(e.target.value);
    } else if (e.target.id === "fecha") {
      setFecha(e.target.value);
    } else if (e.target.id === "EscuelaProveedora") {
      setEscuelaProveedoraS(e.target.value);
    } else if (e.target.id === "campus") {
      setCampusS(e.target.value);
      setPeriodos(null);
      setAreaInteres(null);
      setProgramasInteres(null);
      setPeriodosS("");
      setAreaInteresS("");
      setProgramasInteresS("");
      consultaPeriodos(e.target.value);
    } else if (e.target.id === "periodo") {
      setmustraSelects(true)
      const myJSON = e.target.value;
      const myObj = JSON.parse(myJSON);
//      console.log(myObj.key);

      if (myObj.key === "otro") {
        setPeriodosS('{"periodo":"otro","key":"otro"}');
        //document.getElementById("area").style.display = "none";
        setmustraArea(false)
        //document.getElementById("programa").style.display = "none";
        setmusPrograma(false)
        setAreaInteresS('{"nombre":"otro","key":"otro"}');
        setProgramasInteresS('{"nombre":"otro","key":"otro"}');
      } else {
        

        setPeriodosS(e.target.value);
        if (NivelEvento === "03") {
          var area = '{"nombre":"Prepa","key":"046"}';
          setAreaInteresS(area);
          //document.getElementById("area").style.display = "none";
          setmustraArea(false)
          //document.getElementById("programa").style.display = "block";
          setmusPrograma(true)
          //consultaPrograma(claveCampusClear, e.target.value, area, NivelEvento);
          setmusPrograma(true)
        } else {
          //document.getElementById("area").style.display = "block";
          setmustraArea(true)
          //document.getElementById("programa").style.display = "block";
          setmusPrograma(true)

          setAreaInteres(null);
          setProgramasInteres(null);
          setAreaInteresS("");
          setProgramasInteresS("");
          consultaAreaInteres(campusS, e.target.value);
        } //END ELSE
      }
    } else if (e.target.id === "area") {
      setAreaInteresS(e.target.value);
      setProgramasInteres(null);
      setProgramasInteresS("");
      consultaPrograma(campusS, periodosS, e.target.value);
    } else if (e.target.id === "programa") {
      setProgramasInteresS(e.target.value);
    } else if (e.target.id === "avisos") {
      e.target.checked ? setAviso(1) : setAviso(0);
    } else {
      console.log("ERROR id no encontrado");
    }

    if ( e.target.id === "avisos" && !e.target.checked ) {

        document.getElementById(e.target.id + "P").textContent =
          "Este campo es requerido";
      
    } else {
      // console.log(e.target.id);
      document.getElementById(e.target.id + "P").textContent = "";
    }
  };

  useEffect(() => {
    // se ejecuta cada vez que isEnglish cambie para volver a hacer la consulta a la API
    // debemos llamar a las APIs otra vez para conseguir su versión del nuevo idioma seleccionado
    //consultaCampus(); // esta siempre se debe llamar
    consultaPeriodos(claveCampusClear);

    // debemos checar la selección

    if (claveCampusClear !== "" && periodosS !== "") {
      consultaAreaInteres(claveCampusClear, periodosS, NivelEvento);
    }
    if (claveCampusClear !== "" && periodosS !== "" && areaInteresS !== "") {
      consultaPrograma(claveCampusClear, periodosS, areaInteresS, NivelEvento);
    }
  }, [
    isEnglish,
    claveCampusClear,
    consultaCampus,
    consultaPeriodos,
    consultaAreaInteres,
    consultaPrograma,
    periodosS,
    areaInteresS,
    NivelEvento,
  ]);

  useEffect(() => {
    // este solo se debe ejecutar una vez
    if (localStorage.getItem("ingles") == null) {
      localStorage.setItem("ingles", 0); // si no hay una opción seleccionada lo pongo en español
    }
    setisEnglish(localStorage.getItem("ingles"));
  }, []);

  var campusNameInput;

  if (localStorage.getItem("campusStorageName")) {
    campusNameInput = localStorage.getItem("campusStorageName");
    campusNameInput = campusNameInput.toLowerCase();

    switch (campusNameInput) {
      case "Ciudad de México":
        campusNameInput = "cdmx";
        break;

      case "Ciudad Juárez":
        campusNameInput = "ciudad-juarez";
        break;

      case "Ciudad Obregón":
        campusNameInput = "ciudad-obregon";
        break;

      case "Estado de México":
        campusNameInput = "estado-de-mexico";
        break;

      case "León":
        campusNameInput = "leon";
        break;

      case "Querétaro":
        campusNameInput = "queretaro";
        break;

      case "San Luis Potosí":
        campusNameInput = "san-luis-potosi";
        break;

      case "Santa Fe":
        campusNameInput = "santa-fe";
        break;

      case "Eugenio Garza Lagüera":
        campusNameInput = "prepatec-monterrey";
        break;

      case "Eugenio Garza Sada":
        campusNameInput = "prepatec-monterrey";
        break;

      case "prepatec-guadalajara":
        campusNameInput = "Santa Anita";
        break;

      case "Santa Catarina":
        campusNameInput = "prepatec-monterrey";
        break;

      case "Sonora Norte":
        campusNameInput = "prepatec-sonora-norte";
        break;

      case "Valle Alto":
        campusNameInput = "prepatec-monterrey";
        break;

      case "Cumbres":
        campusNameInput = "prepatec-monterrey";
        break;

      case "Esmeralda":
        campusNameInput = "prepatec-cdmx";
        break;

      default:
        //console.log("");
        break;
    } //switch
  }

  //console.log("campusName=" + campusNameInput);

  const utm_source_input = localStorage.getItem("utm_source_Local");
  const utm_medium_input = localStorage.getItem("utm_medium_Local");
  const utm_cmpa_input = localStorage.getItem("utm_cmpa_Local");
  const utm_term_input = localStorage.getItem("utm_term_Local");

  return (
    <>

<Grid  spacing={2}>
      <div id="formluario" className="formsReg">
        <div className="textoarribaRegistro"></div>
        <div className="row m-0 p-0" style={{ justifyContent: "center" }}>
          <div className="contform col-md-8 col-xs-12">
            {showForm && (
              <form onSubmit={mandarTodo}>
                <div id="hiddens">
                  {Evento.map((eventos) => (
                    <>
                      <input
                        type="hidden"
                        id="nombreRegistro"
                        name="nombreRegistro"
                        value={`Evento ${eventos.event.id_evento}`}
                      />

                      <input
                        type="hidden"
                        name="landing"
                        id="landingForm"
                        value={`Evento-${eventos.event.id_evento}`}
                      />
                      <input
                        type="hidden"
                        name="origenDestino"
                        id="origenDestinoForm"
                        value="Profesional de Preparatoria No Tec"
                      />
                      <input
                        type="hidden"
                        name="retURL"
                        value="https://admision.tec.mx/uat/profeareayprogramatec21/prod_test/savespot_profe_pruebas_todos?gracias=gracias#contact"
                      />
                      <input
                        type="hidden"
                        name="refer"
                        id="refer"
                        value={`https://calendario.tec.mx/evento/${eventos.event.urlname}`}
                      />
                      <input
                        type="hidden"
                        name="cmpa"
                        id="cmpaForm"
                        value={
                          utm_term_input
                            ? utm_term_input
                            : utm_cmpa_input
                            ? utm_cmpa_input
                            : eventos.event.cmpa
                        }
                      />
                      <input
                        type="hidden"
                        name="evento"
                        id="eventoForm"
                        value={eventos.event.fonteva}
                      />
                      <input
                        type="hidden"
                        name="utm_term"
                        id="utm_term"
                        value={utm_term_input}
                      />
                      <input
                        type="hidden"
                        name="contexto"
                        id="contextoForm"
                        value={`${campusNameInput}`}
                      />
                      <input
                        type="hidden"
                        name="contextoInit"
                        id="contextoFormInit"
                        value={`${campusNameInput}`}
                      />
                      <input
                        type="hidden"
                        name="nivel"
                        id="nivelForm"
                        value={eventos.event.nivel}
                      />
                      <input type="hidden" name="flag" id="flag" value="1" />
                      <input
                        type="hidden"
                        name="idLanding"
                        id="idLanding"
                        value="201"
                      />
                      <input
                        type="hidden"
                        name="utm_source"
                        id="utm_source"
                        value={utm_source_input}
                      />
                      <input
                        type="hidden"
                        name="utm_medium"
                        id="utm_medium"
                        value={utm_medium_input}
                      />
                    </>
                  ))}
                </div>

                <div className="preg">
                  <input
                    type="text"
                    placeholder="Nombre(s) del estudiante interesado"
                    className="form-control effect-10"
                    id="nombre"
                    onChange={handleChange}
                  />
                  <span class="focus-bg"></span>
                  <p className="errorForm" id="nombreP"></p>
                </div>
                <div className="preg">
                  <input
                    type="text"
                    placeholder="Primer apellido"
                    className="form-control"
                    id="apellidoPaterno"
                    onChange={handleChange}
                  />
                  <p className="errorForm" id="apellidoPaternoP"></p>
                </div>
                <div className="preg">
                  <input
                    type="text"
                    placeholder="Segundo apellido (opcional)"
                    className="form-control"
                    id="apellidoMaterno"
                    onChange={handleChange}
                  />
                  <p className="errorForm" id="apellidoMaternoP"></p>
                </div>
                <div className="preg">
                  <label htmlFor="telefono">Celular del estudiante interesado</label>
                  <PhoneInput
                    inputProps={{
                      name: "telefono",
                      required: true,
                    }}
                    inputExtraProps={{
                      className: "form-control", // Agrega tu clase personalizada aquí
                    }}
                    inputStyle={{ width: "100%" }}
                    country={"mx"}
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    placeholder="Celular del estudiante interesado"
                  />
                  <p className="errorForm" id="telefonoP"></p>
                </div>

                <div className="FechaPadre">
                  <div className="Fecha">
                    <label htmlFor="fecha">
                      Fecha de nacimiento (dd/mm/aaaa):
                    </label>
                  </div>
                  {/* 24/09/2002 <- FORMATO CORRECTO  data-date-format='DD MMM YYYY'  */}
                  <div className="funfecha">
                    <input
                      type="date"
                      id="fecha"
                      name="fecha"
                      className="form-control"
                      data-date=""
                      data-date-format="DD MMMM YYYY"
                      onChange={handleChange}
                      min={restaFecha(40) + "-01-01"}
                      max={restaFecha(11) + "-12-31"}
                    />
                    <p className="errorForm" id="fechaP"></p>
                  </div>
                </div>

                {/*====================================================SELECTS=========================================================== */}

                <div
                  className="form-group"
                  id="select"
                  style={{ display: "none" }}
                >
                  <select
                    id="campus"
                    name="campus"
                    data-value=""
                    className="form-control"
                    aria-required="true"
                    onChange={handleChange}
                  >
                    <option key="" value="">
                      Selecciona un campus de interés
                    </option>
                    {campus &&
                      campus.map(({ info }) => (
                        <option
                          id={info.clave}
                          key={info.clave}
                          value={JSON.stringify({
                            nombre: info.texto,
                            key: info.clave,
                          })}
                        >
                          {info.texto}
                        </option>
                      ))}
                  </select>
                  <p className="errorForm" id="campusP"></p>
                </div>

                <div className="form-group" id="select">
                  <select
                    id="periodo"
                    name="periodo"
                    data-value=""
                    className="form-control"
                    aria-required="true"
                    onChange={handleChange}
                  >
                    <option key="" selected disabled value="">
                      Fecha esperada de ingreso
                    </option>
                    {periodos && periodos.map(({ info }) => (
                      info.clave !== 'otro' && (
                      <option key={info.clave} value={JSON.stringify({ periodo: info.nombre, key: info.clave, })} >
                        {info.nombre}
                        </option> )
                        ))}
                  </select>
                  <p className="errorForm" id="periodoP"></p>
                </div>

                {mustraSelects && (
                  <>
 
                {mustraArea && (
                  <>
                <div className="form-group" id="select">
                  <select
                    id="area"
                    name="area"
                    data-value=""
                    className="form-control"
                    aria-required="true"
                    onChange={handleChange}
                  >
                    <option key="" value="">
                      Selecciona un área de interés
                    </option>
                    {areaInteres &&
                      areaInteres.map(({ info }) => (
                        <option
                          key={info.clave}
                          value={JSON.stringify({
                            nombre: info.nombre,
                            key: info.clave,
                          })}
                        >
                          {info.nombre}
                        </option>
                      ))}
                  </select>
                  <p className="errorForm" id="areaP"></p>
                </div>
                </>
                )}

                  {mustraPrograma && (
                  <>
                <div className="form-group" id="select">
                  <select
                    id="programa"
                    name="programa"
                    data-value=""
                    className="form-control"
                    aria-required="true"
                    onChange={handleChange}
                  >
                    <option value="">Selecciona un programa de interés</option>
                    {programaInteres &&
                      programaInteres.map(({ info }) => (
                        <option
                          key={info.clave}
                          value={JSON.stringify({
                            nombre: info.nombre,
                            key: info.clave,
                          })}
                        >
                          {info.nombre}
                        </option>
                      ))}
                  </select>
                  <p className="errorForm" id="programaP"></p>
                </div>
                </>
                )}

                </>
                )}
                {/*======================================================================================================================================= */}
                <br />
                <br />
                <Grid container spacing={8}>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={9}>
                    <input
                      id="btnSend"
                      className={`btnreg sendBottom ${
                        isBotonBloqueado ? "bloqueado" : ""
                      }`}
                      type="submit"
                      value={isBotonBloqueado ? "Enviando..." : "REGÍSTRATE"}
                      disabled={isBotonBloqueado}
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  
                </Grid>
                
              </form>
            )}
          </div>
        </div>

        {!showForm && (
          <button
            className="titreg"
            onClick={handleButtonClick}
            style={{ marginBottom: "2%" }}
          >
            Regístrate Aquí
          </button>
        )}
      </div>
      </Grid>
    </>
  );
}

export { Form };
