import React, { useEffect, useState, useCallback } from "react";
import { apiCoreSave, apiUrlGestor, apiUrlSaveSpot, SaveSpotGuarda } from "../global";
import "react-datepicker/dist/react-datepicker.css";
import "./forms.css";
import "./General.css";
import * as moment from "moment";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import sleep from "sleep-promise";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function FormTutor({
  Evento,
  ClCampus,
  NivelEvento,
  EventoRegistrado,
  emailInput,
  viweForm,
  AvisoInput,
  InfoCampus
}) {
  const [campus, setCampus] = useState(null);
  const [periodos, setPeriodos] = useState(null);
  const [areaInteres, setAreaInteres] = useState(null);
  const [programaInteres, setProgramasInteres] = useState(null);
  const [isEnglish, setisEnglish] = useState(0); // ESTE VARIABLE SE REFIERE AL IDIOMA
  const [nombre, setNombre] = useState("");
  const [apellidoPaterno, setApellidoPaterno] = useState("");
  const [apellidoMaterno, setApellidoMaterno] = useState("");
  const [telefono, setTelefono] = useState("");
  const [correochild, setCorreoChild] = useState("");
  const [fecha, setFecha] = useState("");
  const [EscuelaProveedoraS, setEscuelaProveedoraS] = useState("");
  // valores seleccionados de cada campo
  const [campusS, setCampusS] = useState("");
  const [periodosS, setPeriodosS] = useState("");
  const [areaInteresS, setAreaInteresS] = useState("");
  const [programaInteresS, setProgramasInteresS] = useState("");
  const [aviso, setAviso] = useState(AvisoInput);
  const [isBotonBloqueado, setIsBotonBloqueado] = useState(false);
  const [mostrarFormularioC, setMostrarFormularioC] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const handlePhoneChange = (value, country) => {
    setPhoneNumber(value);
    setTelefono(value);
    document.getElementById("telefonoP").textContent = "";
  };

  const [mustraSelects, setmustraSelects] = useState(false);
  const [mustraArea, setmustraArea] = useState(false);
  const [mustraPrograma, setmusPrograma] = useState(false);

  /*  const url = new URL(window.location.href);
  const cValue = url.searchParams.get("c");*/
 
  let navigate = useNavigate();
  //const claveCampusClear = ClCampus[0].replace(/FCOE_/g, '');
  const claveCampusClear =
    ClCampus && ClCampus.length > 0 ? ClCampus.replace(/FCOE_/g, "") : "";
  console.log("claveCampusClear="+claveCampusClear);

  console.log("------------------");
  console.log(Evento);
  console.log("------------------");

  const mandarTodoExiste = (e) => {
   // setNombre("jugo2");
    //mandarTodo(e);
    //Este ya no está en uso
    setIsBotonBloqueado(true);   
    const utm_sourceInput = document.getElementById("utm_source");
    const utm_sourceValue = utm_sourceInput.value;

    const utm_termInput = document.getElementById("utm_term");
    const utm_termValue = utm_termInput.value;
    console.log(utm_termValue);

    const utm_mediumInput = document.getElementById("utm_medium");
    const utm_mediumValue = utm_mediumInput.value;

    const referInput = document.getElementById("refer");
    const referValue = referInput.value;

    const landingFormInput = document.getElementById("landingForm");
    const landingFormValue = landingFormInput.value;

    const eventoInput = document.getElementById("eventoForm");
    const eventoValue = eventoInput.value.replace(/EVE-/g, "");

    const cmpaInput = document.getElementById("cmpaForm");
    const cmpaValue = cmpaInput.value.replace(/CMPA-/g, "");

    const nombreRegistroVal = landingFormValue; //"Eventos " + concetxtoValue; //Eventos Guadalajara
    console.log(nombreRegistroVal)

    const utm_term_input = localStorage.getItem("utm_term_Local");
    const utm_content_input = localStorage.getItem("utm_content_Local");
    const utm_campaign_input = localStorage.getItem("utm_campaign_Local");
    const utm_crm_campaign_input = localStorage.getItem("utm_crm_campaign_Local");
    const utm_cmpa_input = localStorage.getItem("utm_cmpa_Local");
    
    localStorage.setItem('emailSendInfo', correochild);
    localStorage.setItem('utm_correo_padre', emailInput);

    let CampusKey='';
    if (claveCampusClear === "VC") {
      if (localStorage.getItem("campusStorage")) {
         CampusKey = localStorage.getItem("campusStorage");
      } else  CampusKey ="A";
    } else CampusKey=claveCampusClear;

      var cmpaSend = cmpaValue
      if(cmpaSend==="" || cmpaSend === null)
      cmpaSend=InfoCampus[0].campana

      let grandoSend="profesional"
      if(Evento[0].events.nivel==="03")
      grandoSend="prepatec"
    
      //NO ESTA EN USO
    const credentials = {
      email: correochild,
      papa: {"email": emailInput, "formName": Evento[0].events.id_evento+Evento[0].events.urlname,
    "grade" : grandoSend , "cmpa" : cmpaSend, "utmCorreoPadre" : correochild},
      sede: CampusKey,
      avisos: aviso,
      cmpa: cmpaSend,
      event: eventoValue,
      grade : grandoSend,
      formName: Evento[0].events.id_evento+Evento[0].events.urlname,
      urlLanding: referValue,
      utmCmpa: utm_cmpa_input,
      utmTerm: utm_term_input,
      utmContent: utm_content_input,
      utmSource: utm_sourceValue,
      utmCampaign: utm_campaign_input,
      utmMedium: utm_mediumValue,
      utmCrmCampaign: utm_crm_campaign_input,
      refer: referValue,
      //ENVIAR A hubspot
      sendhubspot: "true",
      sendsalesforce: "true",
      sendMixto: "true",
      SaveSpotServer: SaveSpotGuarda
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // mode: 'no-cors', // Establecer el modo no-cors
      body: JSON.stringify(credentials),
    };

    fetch(apiUrlGestor + "enviaRegistro.php", options)
    .then((response) => response.json())
    .then(sleep(2000))
    .then((data) => {
      // Manipular los datos de la respuesta
      console.log(data);

      // alert("se envió con exito");
      localStorage.removeItem("utm_term_Local");
      localStorage.removeItem("utm_medium_Local");
      localStorage.removeItem("utm_source_Local");
      localStorage.removeItem("utm_content_Local");
      localStorage.removeItem("utm_campaign_Local");
      localStorage.removeItem("utm_crm_campaign_Local");
      localStorage.removeItem("utm_cmpa_Local");
      localStorage.removeItem("expiracion");
      setIsBotonBloqueado(false);         
      viweForm(null);
      navigate(
        "../evento/" + Evento[0].event.urlname + "/gracias?g=3#confirmacion"
      );
    })
    .catch((error) => {
      console.log(error);
      setIsBotonBloqueado(false);
    });

    e.preventDefault();
    EventoRegistrado();
    
  }

  const mandarTodo = (e) => {
    setIsBotonBloqueado(true);
    var fechacorregida = moment(fecha);
    if (
      nombre === "" ||
      apellidoPaterno === "" ||
      telefono === "" ||
      correochild === "" ||
      fecha === "" ||
      claveCampusClear === "" ||
      periodosS === "" ||
      areaInteresS === "" ||
      programaInteresS === "" ||
      aviso === 0
    ) {
      
      setIsBotonBloqueado(false);
      //mostramos mensaje de que se debe de subir en los campos que no se han llenado
      if (nombre === "" || nombre.trim() === "") {
        if (isEnglish) {
          document.getElementById("nombreP").textContent = "Ingresa el o los nombres.";
        } else {
          document.getElementById("nombreP").textContent = "Campo Obligatorio";
        }
      }
      if (apellidoPaterno === "" || apellidoPaterno.trim() === "") {
        if (isEnglish) {
          document.getElementById("apellidoPaternoP").textContent =
            "Ingresa el primer apellido.";
        } else {
          document.getElementById("apellidoPaternoP").textContent =
            "Campo Obligatorio";
        }
      }
      if (telefono === "" || telefono.trim() === "") {
        if (isEnglish) {
          document.getElementById("telefonoP").textContent =
            "Ingresa el número de teléfono celular.";
        } else {
          document.getElementById("telefonoP").textContent =
            "Campo Obligatorio";
        }
      }
      if (fecha === "") {
        if (isEnglish) {
          document.getElementById("fechaP").textContent =
            "Ingresa la fecha de nacimiento";
        } else {
          document.getElementById("fechaP").textContent = "Campo Obligatorio";
        }
      }

      if (campusS === "") {
        if (isEnglish) {
          document.getElementById("campusP").textContent =
            "Selecciona un campus.";
        } else {
          document.getElementById("campusP").textContent = "Campo Obligatorio";
        }
      }
      if (periodosS === "") {
        if (isEnglish) {
          document.getElementById("periodoP").textContent =
            "Selecciona una fecha esperada de ingreso.";
        } else {
          document.getElementById("periodoP").textContent = "Campo Obligatorio";
        }
      }
      if(mustraSelects){
      if (areaInteresS === "") {
        if (isEnglish) {
          document.getElementById("areaP").textContent =
            "Selecciona un área de interés.";
        } else {
          document.getElementById("areaP").textContent = "Campo Obligatorio";
        }
      }
      if (programaInteresS === "") {
        if (isEnglish) {
          document.getElementById("programaP").textContent =
            "Selecciona un programa de interés.";
        } else {
          document.getElementById("programaP").textContent =
            "Campo Obligatorio";
        }
      }
    }
      if (aviso === 0) {
        if (isEnglish) {
          document.getElementById("avisosP").textContent =
            "El campo es requerido";
        } else {
          document.getElementById("avisosP").textContent = "Campo Obligatorio";
        }
      }
    } else {
      //localStorage.setItem('paisID', JSON.parse(paisS).key)
      const cmpaInput = document.getElementById("cmpaForm");
      const cmpaValue = cmpaInput.value.replace(/CMPA-/g, "");

      const eventoInput = document.getElementById("eventoForm");
      const eventoValue = eventoInput.value.replace(/EVE-/g, "");

      const contextoForm = document.getElementById("contextoForm");
      const concetxtoValue = contextoForm.value;

      const nivelInput = document.getElementById("nivelForm");
      const NivelValue = nivelInput.value;

      const idLandingInput = document.getElementById("idLanding");
      const idLandingValue = idLandingInput.value;
      console.log(idLandingValue);

      const utm_sourceInput = document.getElementById("utm_source");
      const utm_sourceValue = utm_sourceInput.value;

      const utm_termInput = document.getElementById("utm_term");
      const utm_termValue = utm_termInput.value;

      const utm_mediumInput = document.getElementById("utm_medium");
      const utm_mediumValue = utm_mediumInput.value;

      const referInput = document.getElementById("refer");
      const referValue = referInput.value;

      const landingFormInput = document.getElementById("landingForm");
      const landingFormValue = landingFormInput.value;
      console.log(landingFormValue)

      //const nombreRegistroVal = landingFormValue; //"Eventos " + concetxtoValue; //Eventos Guadalajara

      var nivelSed="profesional";
      if(NivelValue==='03')
        nivelSed="prepatec";

      const utm_content_input = localStorage.getItem("utm_content_Local");
      const utm_campaign_input = localStorage.getItem("utm_campaign_Local");
      const utm_crm_campaign_input = localStorage.getItem("utm_crm_campaign_Local");
      const utm_cmpa_input = localStorage.getItem("utm_cmpa_Local");

      var cmpaSend = cmpaValue
      if(cmpaSend==="" || cmpaSend === null)
      cmpaSend=InfoCampus[0].campana

      // console.error("test");
      //LABEL:
      const credentials = {
        name: nombre,
        lastName: apellidoPaterno,
        secondLastName: apellidoMaterno,
        celularPhone: telefono,
        email: correochild,
        papa: {"email": emailInput, "formName": Evento[0].event.id_evento+Evento[0].event.urlname, "landingUrl" : Evento[0].event.urlname, "grade" : nivelSed, "cmpa" : cmpaSend, "utmCorreoPadre" : correochild},
        birthDate: fechacorregida.format("DD/MM/YYYY"),
        sede: Evento[0].event.id_campus,
        period: JSON.parse(periodosS).key,
        area: JSON.parse(areaInteresS).key,
        program: JSON.parse(programaInteresS).key,
        escuelasprov: EscuelaProveedoraS,
        avisos: aviso,
        cmpa: cmpaSend,
        event: eventoValue,
        contexto: concetxtoValue,
        grade: nivelSed,
        landingUrl: referValue,
        formName: Evento[0].event.id_evento+Evento[0].event.urlname,
        utmCmpa: utm_cmpa_input,
        utmTerm: utm_termValue,
        utmContent: utm_content_input,
        utmSource: utm_sourceValue,
        utmCampaign: utm_campaign_input,
        utmMedium: utm_mediumValue,
        utmCrmCampaign: utm_crm_campaign_input,
        refer: referValue,
        //ENVIAR A hubspot
        sendhubspot: "true",
        sendsalesforce: "true",
        sendMixto: "true",
        SaveSpotServer: SaveSpotGuarda
      };
      //console.log(credentials);
      //console.log(JSON.stringify(credentials))

      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // mode: 'no-cors', // Establecer el modo no-cors
        body: JSON.stringify(credentials),
      };
      //console.log(options);

      fetch(apiUrlGestor + "enviaRegistro.php", options)
        .then((response) => response.json())
        .then(sleep(2000))
        .then((data) => {
          // Manipular los datos de la respuesta
          console.log(data);

          // alert("se envió con exito");
          localStorage.removeItem("utm_term_Local");
          localStorage.removeItem("utm_medium_Local");
          localStorage.removeItem("utm_source_Local");
          localStorage.removeItem("utm_content_Local");
          localStorage.removeItem("utm_campaign_Local");
          localStorage.removeItem("utm_crm_campaign_Local");
          localStorage.removeItem("utm_cmpa_Local");
          localStorage.removeItem("expiracion");
          setIsBotonBloqueado(false);         
          viweForm(null);
          navigate(
            "../evento/" + Evento[0].event.urlname + "/gracias?g=3#confirmacion"
          );
        })
        .catch((error) => {
          console.log(error);
          setIsBotonBloqueado(false);
        });
      //localStorage.setItem('correo', correo)
    }
    e.preventDefault();
    EventoRegistrado();
  };

  // =================== CAMPUS ======================
  const consultaCampus = useCallback(() => {
    var detailsCampus = {
      valor: "05",
    };
    var formBody = [];
    for (var property in detailsCampus) {
      var encodeKey = encodeURIComponent(property);
      var encodeValue = encodeURIComponent(detailsCampus[property]);
      formBody.push(encodeKey + "=" + encodeValue);
    }
    formBody = formBody.join("&");
    fetch(apiCoreSave + "/campus_internacional.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        //console.log(json)
        setCampus(json);
      });
  }, []);

  // =================== PERIODOS ======================
  const consultaPeriodos = useCallback(() => {
    let campusx;
    try {
      campusx = claveCampusClear;
    } catch (error) {
      campusx = "";
    }

    var detailsPeriodo = {
      nivel: NivelEvento,
      campus: campusx,
      ingles: isEnglish,
    };
    var formBody = [];
    for (var property in detailsPeriodo) {
      var encodeKey = encodeURIComponent(property);
      var encodeValue = encodeURIComponent(detailsPeriodo[property]);
      formBody.push(encodeKey + "=" + encodeValue);
    }
    formBody = formBody.join("&");
    fetch(apiCoreSave + "/tecmx/jsons/periodos.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((respPeriodos) => {
        return respPeriodos.json();
      })
      .then((jsonPeriodos) => {
        setPeriodos(jsonPeriodos);
      });
  }, [isEnglish, claveCampusClear, NivelEvento]);

  // =================== AREA ======================
  const consultaAreaInteres = useCallback(
    (claveCampusClear, periodoS, NivelEvento) => {
      let campus, periodo;
      try {
        campus = claveCampusClear;
        periodo = JSON.parse(periodoS).key;
      } catch (error) {
        campus = "";
        periodo = "";
      }

      var detailsPeriodo = {
        campus: campus,
        periodo: periodo,
        nivel: NivelEvento,
        carrera: null,
        ingles: isEnglish,
      };
      var formBody = [];
      for (var property in detailsPeriodo) {
        var encodeKey = encodeURIComponent(property);
        var encodeValue = encodeURIComponent(detailsPeriodo[property]);
        formBody.push(encodeKey + "=" + encodeValue);
      }
      formBody = formBody.join("&");
      fetch(apiCoreSave + "/tecmx/jsons/areas.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody,
      })
        .then((resp) => {
          return resp.json();
        })
        .then((json) => {
          if (json === null) {
            // Realizar una nueva solicitud si el resultado es null
            return fetch(apiCoreSave + "/tecmx/jsons/areas.php", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
              },
              body: formBody,
            }).then((resp) => resp.json());
          } else {
            //console.log(json)
            setAreaInteres(json);
          }
        });
    },
    [isEnglish]
  );

  // =================== PROGRAMA ======================
  const consultaPrograma = useCallback(
    (claveCampusClear, periodoS, areaS, NivelEvento) => {
      let campus, periodo, area;
      try {
        campus = claveCampusClear;
        periodo = JSON.parse(periodoS).key;
        area = JSON.parse(areaS).key;
      } catch (error) {
        campus = "";
        periodo = "";
        area = "";
      }

      var detailsPeriodo = {
        campus: campus,
        periodo: periodo,
        area: area,
        nivel: NivelEvento,
        ingles: isEnglish,
      };
      var formBody = [];
      for (var property in detailsPeriodo) {
        var encodeKey = encodeURIComponent(property);
        var encodeValue = encodeURIComponent(detailsPeriodo[property]);
        formBody.push(encodeKey + "=" + encodeValue);
      }
      formBody = formBody.join("&");
      fetch(apiCoreSave + "/tecmx/jsons/carreras.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody,
      })
        .then((resp) => {
          return resp.json();
        })
        .then((json) => {
          if (json === null || json.length === 0) {
            // Realizar una nueva solicitud si el resultado es null
            return fetch(apiCoreSave + "/tecmx/jsons/carreras.php", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
              },
              body: formBody,
            }).then((resp) => resp.json());
          } else {
            //console.log(json)
            setProgramasInteres(json);
          }
        });
    },
    [isEnglish]
  );

  function esCorreoElectronico(texto) {
  const expresionRegular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
   // const expresionRegular = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return expresionRegular.test(texto);
  }

  function restaFecha(anniosRest) {
    var today = new Date();

    let annios = 1000 * 60 * 60 * 24 * 365 * anniosRest
    let resta = today.getTime() - annios;

    let fechaNueva = new Date(resta);
    var yearN = fechaNueva.getFullYear();

    return yearN;
  }

  //VALIDATE 
  const handleChangeChild = (e) => {
    setCorreoChild(e.target.value);
   // document.getElementById("reformChild").style.display = "block";
    document.getElementById("correoValChild").textContent = "";

    if (esCorreoElectronico(e.target.value)) {
      document.getElementById("btnValidaChild").style.display = "block";
      document.getElementById("btnValidaChild").disabled = false; 
    } else {
     // document.getElementById("btnValidaChild").style.display = "none";
     document.getElementById("btnValidaChild").disabled = true; 
      document.getElementById("correoValChild").textContent = "Ingresa una dirección de correo electrónico valida";
    }
    
  };

  const validarCorreoFnc = () => { 
    var buscaCorreo=true;
    var correoPapa=document.getElementById("correoValidate").value;

    if (!esCorreoElectronico(correochild)) {
    document.getElementById("correoValChild").textContent = "Ingresa una dirección de correo electrónico valida";
    buscaCorreo=false;
    }

    if (correochild === "") {
      document.getElementById("correoValChild").textContent = "Ingresa un correo electrónico";
      buscaCorreo=false; }


    if(correochild===correoPapa)
    {document.getElementById("correoValChild").textContent = "Ingresa un correo distinto";
    buscaCorreo=false;}

    if(buscaCorreo){
      //document.getElementById("correoMsgChild").textContent = "Gracias";

      setIsBotonBloqueado(true);
      document.getElementById("correoValidateChild").disabled = true;
     // document.getElementById("btnValidaChild").style.display = "none";

      fetch(apiUrlSaveSpot + "hubspot/contacts/findByEmail/" + correochild, {
        method: "GET",
        headers: { "Content-Type": "", }, })
        .then((resp) => {
          return resp.json();
        })
        .then((json) => {
          const arrayResp = JSON.parse(JSON.stringify(json));
          setIsBotonBloqueado(false);
          document.getElementById("btnValidaChild").style.display = "none";
          if (arrayResp["status"] === 200){
            document.getElementById("btnValidaChild").style.display = "block";
            setIsBotonBloqueado(true);  
            //ENVIAR DIRECTO
          //setMostrarFormularioC("existe");
          
          const utm_term_input = localStorage.getItem("utm_term_Local");
          const utm_medium_input = localStorage.getItem("utm_medium_Local");
          const utm_source_input = localStorage.getItem("utm_source_Local");
          const utm_content_input = localStorage.getItem("utm_content_Local");
          const utm_campaign_input = localStorage.getItem("utm_campaign_Local");
          const utm_crm_campaign_input = localStorage.getItem("utm_crm_campaign_Local");
          const utm_cmpa_input = localStorage.getItem("utm_cmpa_Local");

          const eventoValue = Evento[0].event.fonteva.replace(/EVE-/g, "");

          var cmpaSend = Evento[0].event.cmpa
          if(cmpaSend==="" || cmpaSend === null)
          cmpaSend=InfoCampus[0].campana

          var gradeSendDir='profesional'
          if(Evento[0].event.nivel==='03')
          gradeSendDir='prepatec'

          localStorage.setItem('utm_correo_padre', correochild);
          const urlLandigSend="https://calendario.tec.mx/evento/"+Evento[0].event.urlname
          //LABEL:

          const credentials = {
            email: correochild,
            papa: {"email": emailInput, "formName": Evento[0].event.id_evento+Evento[0].event.urlname, "landingUrl" : urlLandigSend, "cmpa" : cmpaSend, "grade" : gradeSendDir, "utmCorreoPadre" : correochild},
            sede: Evento[0].event.id_campus,
            avisos: aviso,
            cmpa: cmpaSend,
            event: eventoValue,
            formName: Evento[0].event.id_evento+Evento[0].event.urlname,
            grade: gradeSendDir,
            landingUrl: urlLandigSend,
            utmCmpa: utm_cmpa_input,
            utmTerm: utm_term_input,
            utmMedium: utm_medium_input,
            utmSource: utm_source_input,
            utmContent: utm_content_input,
            utmCampaign: utm_campaign_input,
            utmCrmCampaign: utm_crm_campaign_input,
            //ENVIAR A hubspot
            sendhubspot: "true",
            sendsalesforce: "true",
            sendMixto: "true",
            SaveSpotServer: SaveSpotGuarda
          };

          const options = {
            method: "POST",
            headers: { "Content-Type": "application/json", }, body: JSON.stringify(credentials), };
            
            fetch(apiUrlGestor + "enviaRegistro.php", options)
            .then((response) => response.json())
            .then((data) => {
              console.log(data);

              if(data.data[0].status!==200){
                //Significa que no se guardó en SaveSpot
                //Mostramos el formulario
                setMostrarFormularioC("nuevo");
                document.getElementById("reformChild").style.display = "block";
                document.getElementById("btnValidaChild").style.display = "none";
                setIsBotonBloqueado(false); 
              }
              else{

              localStorage.removeItem("utm_term_Local");
              localStorage.removeItem("utm_medium_Local");
              localStorage.removeItem("utm_source_Local");
              localStorage.removeItem("utm_content_Local");
              localStorage.removeItem("utm_campaign_Local");
              localStorage.removeItem("utm_crm_campaign_Local");
              localStorage.removeItem("utm_cmpa_Local");
              localStorage.removeItem("expiracion");
              setIsBotonBloqueado(false);         
              viweForm(null);
              navigate(
                "../evento/" + Evento[0].event.urlname + "/gracias?g=3#confirmacion"
              );
            }
            })
            .catch((error) => {
              console.log(error);
              setIsBotonBloqueado(false);
            });

          
          //console.table(credentials);
        }
          else{
          setMostrarFormularioC("nuevo");
          document.getElementById("reformChild").style.display = "block";
        }
        
        });
        
        }

    //alert(correoPapa)
  };

  const reiniciarFormChild = () => {
    document.getElementById("correoValidateChild").disabled = false;
    document.getElementById("btnValidaChild").style.display = "block";
    document.getElementById("reformChild").style.display = "none";
    var input = document.getElementById("correoValidateChild");
    input.value = "";
    setMostrarFormularioC("null");
    setCorreoChild(null);
    document.getElementById("correoMsgChild").textContent = "Si ya te registraste antes, por favor usa el mismo correo del estudiante.";

  };

  //============================ DATA ==================================================

  const handleChange = (e) => {
    if (e.target.id === "nombre") {
      setNombre(e.target.value);
    } else if (e.target.id === "apellidoPaterno") {
      setApellidoPaterno(e.target.value);
    } else if (e.target.id === "apellidoMaterno") {
      setApellidoMaterno(e.target.value);
    } else if (e.target.id === "telefono") {
      setTelefono(e.target.value);
    } else if (e.target.id === "fecha") {
      setFecha(e.target.value);
    } else if (e.target.id === "EscuelaProveedora") {
      setEscuelaProveedoraS(e.target.value);
    } else if (e.target.id === "campus") {
      setCampusS(e.target.value);
      setPeriodos(null);
      setAreaInteres(null);
      setProgramasInteres(null);
      setPeriodosS("");
      setAreaInteresS("");
      setProgramasInteresS("");
      consultaPeriodos(e.target.value);
    } else if (e.target.id === "periodo") {
      setmustraSelects(true)
      const myJSON =e.target.value;
      const myObj = JSON.parse(myJSON);
      console.log(myObj.key)

      if(myObj.key==='otro'){
        setPeriodosS('{"periodo":"otro","key":"otro"}');

        //document.getElementById("area").style.display = "none";
        setmustraArea(false)
        //document.getElementById("programa").style.display = "none";
        setmusPrograma(false)

        setAreaInteresS('{"nombre":"otro","key":"otro"}');
        setProgramasInteresS('{"nombre":"otro","key":"otro"}');
      }
      else{
      setPeriodosS(e.target.value);
      if(NivelEvento==='03'){
        var area = '{"nombre":"Prepa","key":"046"}';
        setAreaInteresS(area);

        //document.getElementById("area").style.display = "none";
        setmustraArea(false)

        //document.getElementById("programa").style.display = "block";
        setmusPrograma(true)

        consultaPrograma(claveCampusClear,e.target.value,area, NivelEvento);
      }
      else{
      //document.getElementById("area").style.display = "block";
      setmustraArea(true)
      //document.getElementById("programa").style.display = "block";
      setmusPrograma(true)

      setAreaInteres(null);
      setProgramasInteres(null);
      setAreaInteresS("");
      setProgramasInteresS("");
      consultaAreaInteres(campusS, e.target.value);}//END ELSE

    }
    } else if (e.target.id === "area") {
      setAreaInteresS(e.target.value);
      setProgramasInteres(null);
      setProgramasInteresS("");
      consultaPrograma(campusS, periodosS, e.target.value);
    } else if (e.target.id === "programa") {
      setProgramasInteresS(e.target.value);
    } else if (e.target.id === "avisos") {
      e.target.checked ? setAviso(1) : setAviso(0);
    } else {
      console.log("ERROR id no encontrado");
    }

    if ( e.target.id === "avisos" && !e.target.checked ) {

      document.getElementById(e.target.id + "P").textContent =
        "Este campo es requerido";
    
  } else {
      // console.log(e.target.id);
      document.getElementById(e.target.id + "P").textContent = "";
    }
  };

  useEffect(() => {
    // se ejecuta cada vez que isEnglish cambie para volver a hacer la consulta a la API
    // debemos llamar a las APIs otra vez para conseguir su versión del nuevo idioma seleccionado
    //consultaCampus(); // esta siempre se debe llamar
    consultaPeriodos(claveCampusClear);

    // debemos checar la selección

    if (claveCampusClear !== "" && periodosS !== "") {
      consultaAreaInteres(claveCampusClear, periodosS, NivelEvento);
    }
    if (claveCampusClear !== "" && periodosS !== "" && areaInteresS !== "") {
      consultaPrograma(claveCampusClear, periodosS, areaInteresS, NivelEvento);
    }
  }, [
    isEnglish,
    claveCampusClear,
    consultaCampus,
    consultaPeriodos,
    consultaAreaInteres,
    consultaPrograma,
    periodosS,
    areaInteresS,
    NivelEvento,
  ]);

  useEffect(() => {
    // este solo se debe ejecutar una vez
    if (localStorage.getItem("ingles") == null) {
      localStorage.setItem("ingles", 0); // si no hay una opción seleccionada lo pongo en español
    }
    setisEnglish(localStorage.getItem("ingles"));
    document.getElementById("reformChild").style.display = "none";
  }, []);

  var campusNameInput;

  if (localStorage.getItem("campusStorageName")) {
    campusNameInput = localStorage.getItem("campusStorageName");
    campusNameInput = campusNameInput.toLowerCase();

    switch (campusNameInput) {
      case "Ciudad de México":
        campusNameInput = "cdmx";
        break;

      case "Ciudad Juárez":
        campusNameInput = "ciudad-juarez";
        break;

      case "Ciudad Obregón":
        campusNameInput = "ciudad-obregon";
        break;

      case "Estado de México":
        campusNameInput = "estado-de-mexico";
        break;

      case "León":
        campusNameInput = "leon";
        break;

      case "Querétaro":
        campusNameInput = "queretaro";
        break;

      case "San Luis Potosí":
        campusNameInput = "san-luis-potosi";
        break;

      case "Santa Fe":
        campusNameInput = "santa-fe";
        break;

      case "Eugenio Garza Lagüera":
        campusNameInput = "prepatec-monterrey";
        break;

      case "Eugenio Garza Sada":
        campusNameInput = "prepatec-monterrey";
        break;

      case "prepatec-guadalajara":
        campusNameInput = "Santa Anita";
        break;

      case "Santa Catarina":
        campusNameInput = "prepatec-monterrey";
        break;

      case "Sonora Norte":
        campusNameInput = "prepatec-sonora-norte";
        break;

      case "Valle Alto":
        campusNameInput = "prepatec-monterrey";
        break;

      case "Cumbres":
        campusNameInput = "prepatec-monterrey";
        break;

      case "Esmeralda":
        campusNameInput = "prepatec-cdmx";
        break;

      default:
        console.log("N/A");
        break;
    } //switch
  }

  console.log("campusName=" + campusNameInput);

  const utm_source_input = localStorage.getItem("utm_source_Local");
  const utm_medium_input = localStorage.getItem("utm_medium_Local");
  const utm_cmpa_input = localStorage.getItem("utm_cmpa_Local");
  const utm_term_input = localStorage.getItem("utm_term_Local");

  return (
    <>
            <form>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            {/*  */}
            <input
              type="text"
              placeholder="Correo electrónico del estudiante"
              className="form-control effect-10"
              id="correoValidateChild"
              onBlur={handleChangeChild} />
              <p id="correoMsgChild">Si ya te registraste antes, por favor usa el mismo correo del estudiante.</p>
            <p className="errorForm" id="correoValChild"></p>
          </Grid>

          <Grid item md={5} lg={5} xs={12}>
          <input
              id="reformChild"
              className="btnreg ValidateBottom"
              onClick={reiniciarFormChild}
              type="button"
              value="REINICIAR"
              />
          </Grid>

          <Grid item xs={12}>
            <div id="btnValidaChild" className="formsReg">
              <input
                className={`btnreg ValidateBottom ${isBotonBloqueado ? "bloqueado" : ""}`}
                onClick={validarCorreoFnc}
                type="button"
                value={isBotonBloqueado ? "Procesando..." : "Continuar"}
                disabled={isBotonBloqueado}
              />
            </div><br></br>
          </Grid>
        </Grid>
<br></br>
      </form>

      {mostrarFormularioC && (
  <div>
    {mostrarFormularioC === "existe" && (
         
              <Grid container spacing={8}>
                <Grid item xs={1}></Grid>
                <Grid item xs={9}>
                <form onSubmit={mandarTodoExiste}>
                  
                <div id="hiddens">
                    {Evento.map((eventos) => (
                      <>
                        <input
                          type="hidden"
                          id="nombreRegistro"
                          name="nombreRegistro"
                          value={`${eventos.event.id_evento+eventos.event.urlname}`}
                        />
    
                        <input
                          type="hidden"
                          name="landing"
                          id="landingForm"
                          value={`${eventos.event.urlname}`}
                        />
                        <input
                          type="hidden"
                          name="origenDestino"
                          id="origenDestinoForm"
                          value="Profesional de Preparatoria No Tec"
                        />
                        <input
                          type="hidden"
                          name="retURL"
                          value="https://admision.tec.mx/uat/profeareayprogramatec21/prod_test/savespot_profe_pruebas_todos?gracias=gracias#contact"
                        />
                        <input
                          type="hidden"
                          name="refer"
                          id="refer"
                          value={`https://calendario.tec.mx/evento/${eventos.event.urlname}`}
                        />
                        <input
                          type="hidden"
                          name="cmpa"
                          id="cmpaForm"
                          value={utm_term_input ? utm_term_input : (utm_cmpa_input ? utm_cmpa_input : eventos.event.cmpa)}
                        />
                        <input
                        type="hidden"
                        name="evento"
                        id="eventoForm"
                        value={eventos.event.fonteva}
                        />
                         <input
                          type="hidden"
                          name="utm_term"
                          id="utm_term"
                          value={utm_term_input}
                        />
                        <input
                          type="hidden"
                          name="contexto"
                          id="contextoForm"
                          value={`${campusNameInput}`}
                        />
                        <input
                          type="hidden"
                          name="contextoInit"
                          id="contextoFormInit"
                          value={`${campusNameInput}`}
                        />
                        <input
                          type="hidden"
                          name="nivel"
                          id="nivelForm"
                          value={eventos.event.nivel}
                        />
                        <input type="hidden" name="flag" id="flag" value="1" />
                        <input
                          type="hidden"
                          name="idLanding"
                          id="idLanding"
                          value="201"
                        />
                        <input
                          type="hidden"
                          name="utm_source"
                          id="utm_source"
                          value={utm_source_input}
                        />
                        <input
                          type="hidden"
                          name="utm_medium"
                          id="utm_medium"
                          value={utm_medium_input}
                        />
                      </>
                    ))}
                  </div>

                  <input
                    className={`btnreg sendBottom ${isBotonBloqueado ? "bloqueado" : ""}`}
                    type="submit"
                    value={isBotonBloqueado ? "Enviando..." : "REGÍSTRATE"}
                    disabled={isBotonBloqueado}
                  />
                  </form>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
    )}
    { /* ********************* END FORM EXISTE ********************************* */ }
    {mostrarFormularioC === "nuevo" && (
      <Grid  sx={{ p: 2 }} spacing={2}>
      <div>
        <h2>Perfil del estudiante</h2>
        <p>Para concluir tu registro, completa los siguientes datos.</p>
                  <form onSubmit={mandarTodo}>
                  <div id="hiddens">
                    {Evento.map((eventos) => (
                      <>
                        <input
                          type="hidden"
                          id="nombreRegistro"
                          name="nombreRegistro"
                          value={`Evento ${eventos.event.id_evento}`}
                        />
    
                        <input
                          type="hidden"
                          name="landing"
                          id="landingForm"
                          value={`Evento-${eventos.event.id_evento}`}
                        />
                        <input
                          type="hidden"
                          name="origenDestino"
                          id="origenDestinoForm"
                          value="Profesional de Preparatoria No Tec"
                        />
                        <input
                          type="hidden"
                          name="retURL"
                          value="https://admision.tec.mx/uat/profeareayprogramatec21/prod_test/savespot_profe_pruebas_todos?gracias=gracias#contact"
                        />
                        <input
                          type="hidden"
                          name="refer"
                          id="refer"
                          value={`https://calendario.tec.mx/evento/${eventos.event.urlname}`}
                        />
                        <input
                          type="hidden"
                          name="cmpa"
                          id="cmpaForm"
                          value={utm_term_input ? utm_term_input : (utm_cmpa_input ? utm_cmpa_input : eventos.event.cmpa)}
                        />
                        <input
                        type="hidden"
                        name="evento"
                        id="eventoForm"
                        value={eventos.event.fonteva}
                        />
                         <input
                          type="hidden"
                          name="utm_term"
                          id="utm_term"
                          value={utm_term_input}
                        />
                        <input
                          type="hidden"
                          name="contexto"
                          id="contextoForm"
                          value={`${campusNameInput}`}
                        />
                        <input
                          type="hidden"
                          name="contextoInit"
                          id="contextoFormInit"
                          value={`${campusNameInput}`}
                        />
                        <input
                          type="hidden"
                          name="nivel"
                          id="nivelForm"
                          value={eventos.event.nivel}
                        />
                        <input type="hidden" name="flag" id="flag" value="1" />
                        <input
                          type="hidden"
                          name="idLanding"
                          id="idLanding"
                          value="201"
                        />
                        <input
                          type="hidden"
                          name="utm_source"
                          id="utm_source"
                          value={utm_source_input}
                        />
                        <input
                          type="hidden"
                          name="utm_medium"
                          id="utm_medium"
                          value={utm_medium_input}
                        />
                      </>
                    ))}
                  </div>
    
                  <div className="preg">
                    <input
                      type="text"
                      placeholder="Nombre(s) del estudiante interesado"
                      className="form-control effect-10"
                      id="nombre"
                      onChange={handleChange}
                    />
                    <span class="focus-bg"></span>
                    <p className="errorForm" id="nombreP"></p>
                  </div>
                  <div className="preg">
                    <input
                      type="text"
                      placeholder="Primer apellido del estudiante"
                      className="form-control"
                      id="apellidoPaterno"
                      onChange={handleChange}
                    />
                    <p className="errorForm" id="apellidoPaternoP"></p>
                  </div>
                  <div className="preg">
                    <input
                      type="text"
                      placeholder="Segundo apellido del estudiante (opcional)"
                      className="form-control"
                      id="apellidoMaterno"
                      onChange={handleChange}
                    />
                    <p className="errorForm" id="apellidoMaternoP"></p>
                  </div>
                  <div className="preg"> 
              <label htmlFor="telefono">
              Celular del estudiante interesado
                  </label>              
                <PhoneInput
                  inputProps={{
                  name: 'telefono',
                  required: true
                  }}
                  inputExtraProps={{
                    className: 'form-control', // Agrega tu clase personalizada aquí
                  }}
                  inputStyle={{ width: '100%' }}
                country={'mx'}
                value={phoneNumber}
                onChange={handlePhoneChange}
                placeholder="Celular del estudiante interesado"
                />
                <p className="errorForm" id="telefonoP"></p>


              </div>

                  <div className="FechaPadre">
                    <div className="Fecha">
                      <label htmlFor="fecha">
                      Fecha de nacimiento (dd/mm/aaaa) del estudiante:
                      </label>
                    </div>
                    {/* 24/09/2002 <- FORMATO CORRECTO  data-date-format='DD MMM YYYY'  */}
                    <div className="funfecha">
                      <input
                        type="date"
                        id="fecha"
                        name="fecha"
                        className="form-control"
                        data-date=""
                        data-date-format="DD MMMM YYYY"
                        onChange={handleChange}
                        min={restaFecha(40)+'-01-01'} 
                        max={restaFecha(11)+'-12-31'} 
                      />
                      <p className="errorForm" id="fechaP"></p>
                    </div>
                  </div>
    
                  {/*====================================================SELECTS=========================================================== */}
    
                  <div
                    className="form-group"
                    id="select"
                    style={{ display: "none" }}
                  >
                    <select
                      id="campus"
                      name="campus"
                      data-value=""
                      className="form-control"
                      aria-required="true"
                      onChange={handleChange}
                    >
                      <option key="" value="">
                        Selecciona un campus de interés
                      </option>
                      {campus &&
                        campus.map(({ info }) => (
                          <option
                            id={info.clave}
                            key={info.clave}
                            value={JSON.stringify({
                              nombre: info.texto,
                              key: info.clave,
                            })}
                          >
                            {info.texto}
                          </option>
                        ))}
                    </select>
                    <p className="errorForm" id="campusP"></p>
                  </div>
    
                  <div className="form-group" id="select">
                    <select
                      id="periodo"
                      name="periodo"
                      data-value=""
                      className="form-control"
                      aria-required="true"
                      onChange={handleChange}
                    >
                      <option key="" selected disabled value="">
                        Fecha esperada de ingreso
                      </option>
                      {periodos && periodos.map(({ info }) => (
                      info.clave !== 'otro' && (
                      <option key={info.clave} value={JSON.stringify({ periodo: info.nombre, key: info.clave, })} >
                        {info.nombre}
                        </option> )
                        ))}
                    </select>
                    <p className="errorForm" id="periodoP"></p>
                  </div>

                  {mustraSelects && (
                  <>

                 {mustraArea && (
                  <>
                  <div className="form-group" id="select">
                    <select
                      id="area"
                      name="area"
                      data-value=""
                      className="form-control"
                      aria-required="true"
                      onChange={handleChange}
                    >
                      <option key="" value="">
                        Selecciona un área de interés
                      </option>
                      {areaInteres &&
                        areaInteres.map(({ info }) => (
                          <option
                            key={info.clave}
                            value={JSON.stringify({
                              nombre: info.nombre,
                              key: info.clave,
                            })}
                          >
                            {info.nombre}
                          </option>
                        ))}
                    </select>
                    <p className="errorForm" id="areaP"></p>
                  </div>
                  </>
                )}
                
                {mustraPrograma && (
                  <>
                  <div className="form-group" id="select">
                    <select
                      id="programa"
                      name="programa"
                      data-value=""
                      className="form-control"
                      aria-required="true"
                      onChange={handleChange}
                    >
                      <option value="">Selecciona un programa de interés</option>
                      {programaInteres &&
                        programaInteres.map(({ info }) => (
                          <option
                            key={info.clave}
                            value={JSON.stringify({
                              nombre: info.nombre,
                              key: info.clave,
                            })}
                          >
                            {info.nombre}
                          </option>
                        ))}
                    </select>
                    <p className="errorForm" id="programaP"></p>
                  </div>
                  </>
                )}
                
                </>
                )}
                  {/*======================================================================================================================================= */}
                  {/*================= CHECKBOXES =====================*/}
                  <br />
                  <br />
                  <Grid container spacing={8}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={9}>
                      <input
                        className={`btnreg sendBottom ${isBotonBloqueado ? "bloqueado" : ""}`}
                        type="submit"
                        value={isBotonBloqueado ? "Enviando..." : "REGÍSTRATE"}
                        disabled={isBotonBloqueado}
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </form>
                </div> </Grid>
                )}

    </div>
   )}


    </>
  );
}

export { FormTutor };
